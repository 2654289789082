import 'bootstrap/dist/css/bootstrap.min.css'
import { jwtDecode } from 'jwt-decode'
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/main.scss'
import UserContext from './contexts/UserContext'
import Router from './Router'
import * as serviceWorker from './serviceWorker'

const token = localStorage.getItem('token')
let user = { token: '', isAuth: false }

if (token) {
    user = { ...jwtDecode(token), isAuth: true, token }
}

ReactDOM.render(
    <React.StrictMode>
        <UserContext.Provider value={user}>
            <Router />
        </UserContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
