import styled from '@emotion/styled'
import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'
import Footer from './partials/Footer'
import Header from './partials/header/Header'
import HeaderTop from './partials/header/partials/HeaderTop'

const UserInterface = ({ children }) => {
    const location = useLocation()
    const user = useContext(UserContext)

    const [scrollY, setScrollY] = useState()

    window.onscroll = () => {
        setScrollY(window.scrollY)
    }

    if (location.pathname === '/admin/login') {
        return <main>{children}</main>
    } else if (location.pathname.indexOf('/admin') !== -1 && user.isAuth) {
        return (
            <div className="d-flex flex-row">
                <Header />
                <AdminMain>
                    <HeaderTop />
                    {children}
                </AdminMain>
            </div>
        )
    }

    return (
        <div>
            <Header scrollY={scrollY} />
            <main>{children}</main>
            {!location.pathname.includes('/palmares') && <Footer />}
        </div>
    )
}

const AdminMain = styled.main`
    width: 100%;
    background-color: #181818;

    & .content {
        padding: 1em;
    }
`

export default UserInterface
